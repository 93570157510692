
















import Vue from "vue";
export default Vue.extend({
  props: ["message", "canCreateClass"],
  methods: {
    redirect() {
      this.$router.push({ name: "add" });
    }
  }
});
